import React, { createContext, useLayoutEffect, useState, useContext } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider as RaMuiThemeProvider, createMuiTheme } from 'ra-ui-materialui/esm/Theme'
import mapValues from "lodash/mapValues";

const baseTheme = {
  typography: {
    fontFamily: ['"Titillium Web"', "Helvetica", "sans-serif"].join(","),
  },
}

const basePalette = {
  type: "dark",
  background: {
    default: "#333333",
  },
  primary: { main: "#09BAA6", contrastText: "#ffffff" },
  secondary: { main: "#ee7268", contrastText: "#ffffff" },
  error: { main: "#ee7268", contrastText: "#ffffff" },
}

const options = {
  dark: {
    ...baseTheme,
    palette: {
      ...basePalette
    }
  },
  light: {
    ...baseTheme,
    palette: {
      ...basePalette,
      type: 'light',
      background: {
        default: '#ffffff'
      }
    }
  }
}

export const themes = mapValues(options, (opts) => createTheme(opts))
const raThemes = mapValues(options, (opts) => createMuiTheme(opts))

const InvertableThemeContext = createContext();
const defaultTheme = 'dark'

export const useInvertableTheme = () => useContext(InvertableThemeContext)

export const InvertableThemeProvider = ({ children }) => {
  const [themeType, setThemeTypeState] = useState(defaultTheme);

  const setThemeClass = (type) => {
    document.body.classList.remove(`theme-${themeType}`)
    document.body.classList.add(`theme-${type}`)
  }

  const toggleThemeType = () => {
    setThemeType(themeType === 'dark' ? 'light' : 'dark')
  }

  const setThemeType = (type) => {
    setThemeClass(type)
    setThemeTypeState(type)
    localStorage.setItem('theme', type)
  }

  useLayoutEffect(() => {
    const theme = localStorage.getItem('theme') || themeType
    setThemeType(theme)
  }, [])

  return (
    <InvertableThemeContext.Provider value={{ themeType, setThemeType, toggleThemeType }}>
      <RaMuiThemeProvider theme={raThemes[themeType]}>
        <MuiThemeProvider theme={themes[themeType]}>{children}</MuiThemeProvider>
      </RaMuiThemeProvider>
    </InvertableThemeContext.Provider>
  );
};
