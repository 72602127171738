import IconButton from "@material-ui/core/IconButton";
import DarkIcon from "@material-ui/icons/Brightness4";
import LightIcon from "@material-ui/icons/Brightness7";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useInvertableTheme } from "../../theme";
import React from "react";

const ThemeIcon = ({ themeType }) => {
  switch (themeType) {
    case "dark":
      return <DarkIcon />;
    default:
      return <LightIcon />;
  }
};

export const InvertableThemeButton = () => {
  const { themeType, toggleThemeType } = useInvertableTheme();
  return (
    <IconButton onClick={toggleThemeType} style={{ outline: 0 }} size="small">
      <ThemeIcon themeType={themeType} />
    </IconButton>
  );
};

export const LoadingButton = ({
  onClick,
  loading,
  disabled,
  children,
  ...props
}) => {
  return (
    <Button
      onClick={(ev) => {
        ev.stopPropagation();
        if (onClick) {
          onClick();
        }
      }}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
          variant="indeterminate"
        />
      ) : null}
      {children}
    </Button>
  );
};
